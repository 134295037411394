import { FileType } from '@prisma/client';
import { trpc } from '~/utils/trpc';
import { formatFileName } from './stringHelpers';
import { Canvg } from 'canvg';

export const convertBlobToBase64: (blob: Blob) => Promise<string> = (blob) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onerror = reject;
		reader.onload = () => {
			const b64 = window.btoa(reader.result as string);
			resolve(b64);
		};
		reader.readAsBinaryString(blob);
	});

export const convertUrlToBase64: (url: string) => Promise<string> = (url) => {
	return convertUrlToBlob(url).then((b) => convertBlobToBase64(b));
};

export const convertUrlToBlob = (url: string) => {
	return fetch(url).then((r) => r.blob());
};

export type UploadDocumentResult = {
	preSignedUploadUrl: string;
	fileName: string;
	s3Location: string;
	s3Bucket: string;
	s3Key: string;
	size: number;
	fileType: FileType;
	previewUrl: string;
};
// Need new type for tRPC client
export async function uploadDocument(
	file: File,
	fileName: string,
	trpcClient: ReturnType<typeof trpc.useUtils>['client'],
	jobId?: number,
	quoteId?: number
): Promise<UploadDocumentResult> {
	const extension = fileName.split('.').pop();

	const matchFileType = extension?.toUpperCase();

	if (!matchFileType || !Object.values(FileType).includes(matchFileType as FileType)) {
		throw new Error('File Type not supported');
	}

	const formattedFileName = formatFileName(fileName);

	let s3Destination: {
		preSignedUploadUrl: string;
		fileName: string;
		s3Location: string;
		s3Bucket: string;
		s3Key: string;
	};
	if (jobId) {
		s3Destination = await trpcClient.documents.generatePreSignedUrl.mutate({
			fileName: formattedFileName,
			jobId,
		});
	} else {
		if (!quoteId) {
			throw new Error('Need a job id or a quote id to upload a document');
		}

		s3Destination = await trpcClient.documents.generatePreSignedUrlForQuote.mutate({
			fileName: formattedFileName,
			quoteId,
		});
	}

	const previewUrl = await trpcClient.documents.generatePreSignedPreviewUrl.mutate({
		bucket: s3Destination.s3Bucket,
		key: s3Destination.s3Key,
		fileType: matchFileType as FileType,
	});

	const uploadResponse = await fetch(s3Destination.preSignedUploadUrl, {
		method: 'PUT',
		body: file,
	});

	if (uploadResponse.status != 200) {
		throw new Error('There was a problem uploading the file to S3');
	}

	return {
		...s3Destination,
		size: file.size,
		fileType: matchFileType as FileType,
		previewUrl,
	};
}

export async function customerUploadDocument(
	file: File,
	fileName: string,
	jobId: number,
	estimateId: number,
	uniqueAccessId: string,
	trpcClient: ReturnType<typeof trpc.useUtils>['client']
): Promise<UploadDocumentResult> {
	const extension = fileName.split('.').pop();

	const matchFileType = extension?.toUpperCase();

	if (!matchFileType || !Object.values(FileType).includes(matchFileType as FileType)) {
		throw new Error('File Type not supported');
	}

	const formattedFileName = formatFileName(fileName);

	const s3Destination = await trpcClient.customerQuote.documentGeneratePreSignedUrl.mutate({
		fileName: formattedFileName,
		jobId,
		estimateId,
		uniqueAccessId,
	});

	const previewUrl = await trpcClient.customerQuote.documentGeneratePreSignedPreviewUrl.mutate({
		bucket: s3Destination.s3Bucket,
		key: s3Destination.s3Key,
		fileType: matchFileType as FileType,
		jobId,
		estimateId,
		uniqueAccessId,
	});

	const uploadResponse = await fetch(s3Destination.preSignedUploadUrl, {
		method: 'PUT',
		body: file,
	});

	if (uploadResponse.status != 200) {
		throw new Error('There was a problem uploading the file to S3');
	}

	return {
		...s3Destination,
		size: file.size,
		fileType: matchFileType as FileType,
		previewUrl,
	};
}

export const getFileIcon = (fileType: string | FileType) => {
	let icon = 'file';
	let type = fileType;
	if (fileType === 'CSV') {
		icon = 'file-csv';
	} else if (fileType === 'PDF') {
		icon = 'file-pdf';
	} else if (fileType === 'JPG' || fileType === 'JPEG' || fileType === 'PNG' || fileType === 'GIF' || fileType === 'WEBP' || fileType === 'SVG') {
		icon = 'file-image';
		type = 'image';
	} else if (fileType === 'QBX' || fileType === 'XLS' || fileType === 'XLSX') {
		icon = 'table-list';
	}

	return { icon: icon, type: type };
};

export const fileExistsAtUrl = (fileURL: string) => {
	const http = new XMLHttpRequest();

	http.open('HEAD', fileURL, false);
	http.send();

	return http.status === 200;
};

export const blobToPDFFile = (blobData: Blob, fileName: string): File => {
	return new File([blobData], fileName, { type: 'application/pdf' });
};

export const domElementToImage = async (element: Node, canvas: OffscreenCanvas, fileName: string, type: string): Promise<File> => {
	const domString = new XMLSerializer().serializeToString(element);
	const ctx = canvas.getContext('2d');
	const v = await Canvg.from(ctx!, domString);
	await v.render();
	const blob = await canvas.convertToBlob();
	return new File([blob], fileName, { type });
};
