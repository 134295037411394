import { useInView } from 'react-intersection-observer';
import { formatDate } from '~/components/utils';
import useInfinite from '~/hooks/useInfinite';
import { DashboardPurchaseOrderItem } from '../DashboardItems/DashboardPurchaseOrderItem';
import { DashboardCard } from './DashboardCard';
import { DateHelper } from '@utils/date.helper';
import { CONSTANTS } from '@utils/constants';

interface Props {
	activeBranchIds: number[];
}

export const DashboardRejectedPurchaseOrdersCard = ({ activeBranchIds }: Props) => {
	const { ref, inView } = useInView();

	const {
		query: { isLoading },
		data: purchaseOrders,
		fetchNextPage,
	} = useInfinite(
		'purchaseOrders',
		'allForDashboard',
		{
			limit: CONSTANTS.defaultLimitInfinite,
			selectedStatuses: ['NOT_ACCEPTED'],
			branchIds: activeBranchIds,
			orderBy: { updatedAt: 'desc' },
		},
		{ enabled: inView }
	);

	return (
		<DashboardCard ref={ref} icon="cart-circle-xmark" title="Rejected POs" isLoading={isLoading} fetchNextPage={fetchNextPage}>
			<div className="space-y-3">
				{purchaseOrders.map((purchaseOrder) => {
					const isToday = DateHelper.isDateToday(purchaseOrder.rejectedAt);
					const displayDate = purchaseOrder.rejectedAt
						? isToday
							? 'Today'
							: formatDate(purchaseOrder.rejectedAt, false, 'short')
						: undefined;

					return (
						<DashboardPurchaseOrderItem
							key={purchaseOrder.id}
							purchaseOrder={purchaseOrder}
							displayDate={displayDate}
							backgroundColors="bg-red-50 hover:bg-red-100"
						/>
					);
				})}
			</div>
		</DashboardCard>
	);
};
