import { Customer, Job, Message } from '@prisma/client';
import { DateHelper } from '@utils/date.helper';
import { useRouter } from 'next/router';
import { formatDate, formatTime } from '~/components/utils';

interface Props {
	message: Message;
	job: Job | null;
	customer?: Customer;
}

export const DashboardMessageItem = ({ message, job, customer }: Props) => {
	const router = useRouter();

	const isToday = DateHelper.isDateToday(message.createdAt);

	if (!job || !customer) return null;

	return (
		<div className="text-sm space-y-2">
			<div className="flex items-center">
				<h4 className="ml-2 font-semibold text-gray-900">{job.name}</h4>
				<div className="ml-auto text-xs text-gray-600">
					{`${isToday ? 'Today' : formatDate(message.createdAt, false, 'short')}, ${formatTime(message.createdAt, true)}`}
				</div>
			</div>
			<div onClick={() => router.push(`/jobs/${job.id}`)} className="bg-gray-50 hover:bg-gray-100 rounded-lg p-3 cursor-pointer space-y-1">
				<p className="font-medium text-gray-900">{customer.name}</p>
				<p className="text-gray-700">{message.message}</p>
			</div>
		</div>
	);
};
