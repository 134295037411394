import { Job, Quote, Task } from '@prisma/client';
import { DateHelper } from '@utils/date.helper';
import { useRouter } from 'next/router';
import { formatDate } from '~/components/utils';
import { FAIcon } from '~/components/utils/FAIcons';

interface Props {
	task: Task;
	job: Job;
	quote?: Quote;
}

export const DashboardQuoteTaskItem = ({ task, job, quote }: Props) => {
	const router = useRouter();

	const isToday = DateHelper.isDateToday(task.startingAt);
	const displayDate = task.startingAt ? (isToday ? 'Today' : formatDate(task.startingAt, false, 'short')) : undefined;

	if (!quote) return null;

	return (
		<div
			onClick={() => router.push(task.destinationUrl ? task.destinationUrl : `/jobs/${job.id}`)}
			className="bg-gray-50 hover:bg-gray-100 rounded-lg p-3 space-y-2 cursor-pointer"
		>
			<div className="flex font-medium text-gray-800">
				<h4 className="font-semibold text-gray-900">Quote {quote.number}</h4>
				<div className="flex items-center ml-auto space-x-2">
					{!!displayDate && <div className="text-xs text-gray-600">{displayDate}</div>}
					<div className="text-gray-400">
						<FAIcon icon="arrow-up-right-from-square" />
					</div>
				</div>
			</div>
			<div className="text-sm space-y-1">
				<p className="text-gray-700">{job.name}</p>
			</div>
		</div>
	);
};
