import { Job, Transaction } from '@prisma/client';
import { DateHelper } from '@utils/date.helper';
import { titlifyEnum } from '@utils/stringHelpers';
import { formatDate, formatDollarAmount } from '~/components/utils';
import { FAIcon } from '~/components/utils/FAIcons';

interface Props {
	transaction: Transaction;
	job: Job;
}

export const DashboardTransactionItem = ({ transaction, job }: Props) => {
	const isToday = DateHelper.isDateToday(transaction.createdAt);

	return (
		<div className="p-1 space-y-2">
			<div className="text-sm space-y-1">
				<div className="flex items-center">
					<FAIcon className="w-2" icon="dollar-sign" />
					<h4 className="ml-2 font-semibold text-gray-900">{titlifyEnum(transaction.transactionType)}</h4>
					<div className="ml-auto text-xs text-gray-600">{isToday ? 'Today' : formatDate(transaction.createdAt, false, 'short')}</div>
				</div>
				<div className="ml-4 space-y-1">
					<p className="text-gray-700">{job.name}</p>
					<p className="text-gray-700"> {formatDollarAmount(+transaction.amount)}</p>
				</div>
			</div>
		</div>
	);
};
