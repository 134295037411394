import { useInView } from 'react-intersection-observer';
import { formatDate } from '~/components/utils';
import useInfinite from '~/hooks/useInfinite';
import { DashboardPurchaseOrderItem } from '../DashboardItems/DashboardPurchaseOrderItem';
import { DashboardCard } from './DashboardCard';
import { DateHelper } from '@utils/date.helper';
import { CONSTANTS } from '@utils/constants';

interface Props {
	activeBranchIds: number[];
}

export const DashboardDelayedPurchaseOrdersCard = ({ activeBranchIds }: Props) => {
	const { ref, inView } = useInView();

	const {
		query: { isLoading },
		data: purchaseOrders,
		fetchNextPage,
	} = useInfinite(
		'purchaseOrders',
		'allForDashboard',
		{
			limit: CONSTANTS.defaultLimitInfinite,
			selectedStatuses: ['AWAITING_DELIVERY'],
			isArrivalLate: true,
			branchIds: activeBranchIds,
			orderBy: { expectedArrivalDate: 'asc' },
		},
		{ enabled: inView }
	);

	return (
		<DashboardCard ref={ref} icon="cart-circle-exclamation" title="Delayed POs" isLoading={isLoading} fetchNextPage={fetchNextPage}>
			<div className="space-y-3">
				{purchaseOrders.map((purchaseOrder) => {
					const isToday = DateHelper.isDateToday(purchaseOrder.expectedArrivalDate);
					const displayDate = purchaseOrder.expectedArrivalDate
						? isToday
							? 'Today'
							: formatDate(purchaseOrder.expectedArrivalDate, false, 'short')
						: undefined;

					return (
						<DashboardPurchaseOrderItem
							key={purchaseOrder.id}
							purchaseOrder={purchaseOrder}
							displayDate={displayDate}
							backgroundColors="bg-orange-50 hover:bg-orange-100"
						/>
					);
				})}
			</div>
		</DashboardCard>
	);
};
