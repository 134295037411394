import { DateTime } from 'luxon';
import { Context } from '~/server/context';

type ScheduledJobWithTimezone = {
	start: Date;
	end: Date;
	completedAt?: Date | null;
	startedAt?: Date | null;
	startTimezoned?: string;
	endTimezoned?: string;
};
export class DateHelper {
	public static localizeUTC(utcDate: Date, timezone: string) {
		return DateTime.fromJSDate(utcDate, { zone: timezone }).toJSDate();
	}

	public static localizeUTCSafe(utcDate: Date | null, timezone: string) {
		if (utcDate) {
			return DateTime.fromJSDate(utcDate, { zone: timezone }).toJSDate();
		}
		return utcDate;
	}

	// Keep in mind that during serialization timezones will be dropped, and these
	// dates will be sent back to the client as UTC.
	public static translateDatesForScheduledJob<T extends ScheduledJobWithTimezone>(scheduledJob: T, ctx: Context) {
		this.translateDates(scheduledJob, ctx.timezone);
	}

	public static translateDates<T extends ScheduledJobWithTimezone>(scheduledJob: T, timezone: string) {
		scheduledJob.start = DateHelper.localizeUTC(scheduledJob.start, timezone);
		scheduledJob.end = DateHelper.localizeUTC(scheduledJob.end, timezone);
		scheduledJob.completedAt = scheduledJob.completedAt && DateHelper.localizeUTCSafe(scheduledJob.completedAt, timezone);
		scheduledJob.startedAt = scheduledJob.startedAt && DateHelper.localizeUTCSafe(scheduledJob.startedAt, timezone);
		scheduledJob.startTimezoned = DateTime.fromJSDate(scheduledJob.start, { zone: timezone }).toString();
		scheduledJob.endTimezoned = DateTime.fromJSDate(scheduledJob.end, { zone: timezone }).toString();
	}

	public static isDateToday(_date: Date | string | null | undefined) {
		if (!_date) return false;

		const today = DateTime.now();
		const date = DateTime.fromJSDate(new Date(_date));
		const isToday = date.hasSame(today, 'day') && date.hasSame(today, 'month') && date.hasSame(today, 'year');

		return isToday;
	}

	public static convertUNIXTimestampToDate(unixTimestamp: number) {
		return new Date(unixTimestamp * 1000);
	}
}
