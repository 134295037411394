import { useRouter } from 'next/router';
import { ButtonGroup } from '~/components/utils/buttons';

export const DashboardPageToggle = () => {
	const router = useRouter();

	const isAnalytics = router.pathname.toLowerCase().includes('analytics');

	return (
		<ButtonGroup
			initActiveButton={isAnalytics ? 'Analytics' : 'Operations'}
			buttons={[
				{
					label: 'Operations',
					onClick: () => router.push('/'),
				},
				{ label: 'Analytics', onClick: () => router.push('/analytics') },
			]}
			buttonClasses="text-base font-medium"
			sendActiveButton={() => {
				// NOTE: this function is required by the ButtonGroup component
				// but is not necessary
			}}
		/>
	);
};
