import { WithFeatureFlags, getEnabledFeatureFlags } from '@utils/featureFlags';
import { trpc } from '@utils/trpc';
import Head from 'next/head';
import { NextRequest } from 'next/server';
import React, { useState } from 'react';
import { PrimaryColumn } from '~/components/layout/';
import { DashboardGrid } from '~/components/pages/dashboard/DashboardGrid';
import { DashboardHeader } from '~/components/pages/dashboard/DashboardHeader';
import { DefaultLayout } from '~/layout/DefaultLayout';
import { NextPageWithLayout } from '~/pages/_app';
import { JobIndexContextProvider } from '~/react_context/JobIndexContext';

const DashboardIndex: NextPageWithLayout<WithFeatureFlags> = () => {
	const { data: branches } = trpc.branch.readAll.useQuery();
	const [activeBranches, setActiveBranches] = useState<string[]>([]);

	return (
		<>
			<Head>
				<title>Dashboard | Glazier</title>
			</Head>

			<PrimaryColumn>
				<DashboardHeader branches={branches ?? undefined} activeBranches={activeBranches} setActiveBranches={setActiveBranches} />
				<DashboardGrid activeBranchIds={activeBranches.map((branchId) => Number(branchId))} />
			</PrimaryColumn>
		</>
	);
};

DashboardIndex.getLayout = function (page: React.ReactElement, props: WithFeatureFlags) {
	return (
		<DefaultLayout backgroundColor="bg-gray-100" featureFlags={props.featureFlags}>
			<JobIndexContextProvider>{page}</JobIndexContextProvider>
		</DefaultLayout>
	);
};

export async function getServerSideProps(ctx: { req: NextRequest }) {
	return {
		props: getEnabledFeatureFlags(ctx.req),
	};
}

export default DashboardIndex;
