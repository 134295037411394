import { DateHelper } from '@utils/date.helper';
import { useRouter } from 'next/router';
import { formatDate } from '~/components/utils';
import { KeyDateDashboardItem } from '~/server/schema/keyDate.schema';

interface Props {
	keyDate: KeyDateDashboardItem;
	color: string;
}

export const DashboardKeyDateItem = ({ keyDate, color }: Props) => {
	const router = useRouter();

	const isToday = DateHelper.isDateToday(keyDate.dueDate);

	return (
		<div className="p-2 space-y-1 hover:bg-gray-100 rounded-lg cursor-pointer" onClick={() => router.push(`/jobs/${keyDate.jobId}`)}>
			<div className="text-sm space-y-1">
				<div className="flex items-center">
					<div className={`w-1.5 h-1.5 ${color} rounded-full`} />
					<h4 className="ml-2 font-semibold text-gray-900">{keyDate.name}</h4>
					<div className="ml-auto text-xs text-gray-600">{isToday ? 'Today' : formatDate(keyDate.dueDate, false, 'short')}</div>
				</div>
				<p className="text-gray-700">{keyDate.jobName}</p>
			</div>
		</div>
	);
};
